import React from "react";
import { useTranslation } from "gatsby-plugin-react-i18next";
import ComponentWow from "../../Wow";
import FormOnly from "../../Form/Contact";

const ContactForm = () => {
  const { t } = useTranslation();
  return (
    <>
      <ComponentWow>
        <div className="container-fluid pt-5">
          <div className="text-center mb-4 d-flex flex-column">
            <h5 className="section-title px-5">
              <span className="px-2">{t("contact.title")}</span>
            </h5>
            <h6 className="section-title px-5">
              <span className="px-2">{t("contact.desc")}</span>
            </h6>
          </div>
          <div className="row px-xl-5">
            <div className="col-lg-7 mb-5">
              <FormOnly />
            </div>
            <div className="col-lg-5 mb-5">
              <div className="position-relative h-100 rounded overflow-hidden">
                <iframe
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "cover",
                    border: 0,
                  }}
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3965.4313612180185!2d106.89682881744385!3d-6.338133099999994!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e69ecd8727d0375%3A0x100f7e773f9f249b!2sJl.%20Raya%20Cilangkap%20No.10%2C%20RT.2%2FRW.8%2C%20Cilangkap%2C%20Kec.%20Cipayung%2C%20Kota%20Jakarta%20Timur%2C%20Daerah%20Khusus%20Ibukota%20Jakarta%2013870!5e0!3m2!1sid!2sid!4v1659490273509!5m2!1sid!2sid"
                  frameBorder="0"
                  allowFullScreen
                  aria-hidden="false"
                  tabIndex={0}
                ></iframe>
              </div>
            </div>
          </div>
        </div>
      </ComponentWow>
    </>
  );
};

export default ContactForm;
