import React from "react";
import { graphql } from "gatsby";
import { Helmet } from "react-helmet";
import { useTranslation } from "gatsby-plugin-react-i18next";
import ContactForm from "../components/Contact/Form";
import Layout from "../templates/layout";
import ProductHeader from "../components/Products/Header";

const ContactUs = () => {
  const { t } = useTranslation();

  return (
    <>
      <Helmet>
        <title>{`${t("meta.contact.title")} | ${t("meta.title")}`}</title>
        <meta content={t("meta.contact.keyword")} name="keywords" />
        <meta content={t("meta.contact.desc")} name="description" />
        <meta name="robots" content="index, follow" />
      </Helmet>
      <Layout currentLink="contact" description={t("meta.contact.desc")}>
        <>
          <ProductHeader title={t("contactUs")} linkTitle={t("menu.contact")} />
          <ContactForm />
        </>
      </Layout>
    </>
  );
};

export default ContactUs;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
